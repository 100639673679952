import React, { lazy } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import ErrorBoundaryPage from "../component/ErrorBoundary/ErrorBoundaryPage";
import { homePage } from "../constants/constants";
import { ToolBoxDataType } from "../interfaces/ToolBoxData";
import { CoachingToolPage } from "../Pages/CoachingTool.tsx/CoachingTool";
import ContactForm from "../Pages/ContactForm/ContactForm";
import ThankYouPage from "../Pages/Coolbag/ThankYouPage";
import { DynamicPage } from "../Pages/DynamicPage/DynamicPage";
import ForgotPasswordPage from "../Pages/ForgotPassword/ForgotPasswordPage";
import { HomePage } from "../Pages/Home/HomePage";
import ChangePassword from "../Pages/ChangePassword/ChangePassword";
import Login from "../Pages/Login/Login";
import { NotFoundPage } from "../Pages/NotFound/NotFoundPage";
import OrderKitForm from "../Pages/OrderKitForm/OrderKitForm";
import RegisterFormHCP from "../Pages/Register/RegisterFormHCP";
import RegisterFormMain from "../Pages/Register/RegisterFormMain";
import RegisterFormPatient from "../Pages/Register/RegisterFormPatient";
import ThankYouForRegisteringPage from "../Pages/Register/ThankYouForRegisteringPage";
import { GoalSettingWrapper } from "../Pages/Toolbox/Goal/GoalSettingsWrapper";
import MyProgresspage from "../Pages/Toolbox/MyProgress/MyProgressPage";
import { ReminderPage } from "../Pages/Toolbox/Reminder/ReminderPage";
import ToolBoxSettingsPage from "../Pages/Toolbox/ToolBoxSettingsPage";
import { YourConditionPage } from "../Pages/YourCondition.tsx/YourConditionPage";
import PrivateRoute from "./PrivateRoute";
import { Restriction } from "../interfaces/Restriction";

const AccountPage = lazy(() => import("../Pages/Profile/ProfilePage"));
const CoolbagForm = lazy(() => import("../Pages/Coolbag/CoolbagForm"));

export const Routes: React.FC = () => {
  const { pathname } = useLocation();

  return (
    <ErrorBoundaryPage>
      <Switch>
        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />

        {/*IDACIO*/}
        <PrivateRoute path="/my-idacio/understanding-treatment" restriction={Restriction.PATIENT}>
          <DynamicPage/>
        </PrivateRoute>

        <PrivateRoute path="/my-idacio/how-to-inject-idacio" restriction={Restriction.PATIENT}>
          <DynamicPage/>
        </PrivateRoute>

        <PrivateRoute path="/my-idacio/caring-for-your-medication" restriction={Restriction.PATIENT}>
          <DynamicPage/>
        </PrivateRoute>

        <PrivateRoute path="/my-idacio/help-and-support" restriction={Restriction.PATIENT}>
          <DynamicPage/>
        </PrivateRoute>

        <PrivateRoute exact path="/my-idacio/my-progress/goals/settings" restriction={Restriction.PATIENT}>
          <GoalSettingWrapper editPageUrl="/my-idacio/my-progress/goals/settings/goal/" />
        </PrivateRoute>

        <PrivateRoute path="/my-idacio/my-progress/goals" restriction={Restriction.PATIENT}>
          <GoalSettingWrapper editPageUrl="/my-idacio/my-progress/goals/settings/goal/" />
        </PrivateRoute>

        <Redirect
            from="/my-idacio/my-progress/goals"
            to="/my-idacio/my-progress/goals/settings"
        />

        <PrivateRoute
            exact
            path="/my-idacio/my-progress/log-medication/settings"
            restriction={Restriction.PATIENT}>
          <ToolBoxSettingsPage
              dataType={ToolBoxDataType.MEDICATIONS}
              editPageUrl="/my-idacio/my-progress/log-medication/settings/medication/"
              showTime={true}
              showDate={true}
              isMedication={true}
          />
        </PrivateRoute>

        <PrivateRoute path="/my-idacio/my-progress/notifications" restriction={Restriction.PATIENT}>
          <ReminderPage />
        </PrivateRoute>

        <PrivateRoute exact path="/my-idacio/my-progress" restriction={Restriction.PATIENT}>
          <MyProgresspage />
        </PrivateRoute>

        <PrivateRoute path="/my-idacio/coolbag/thank-you" restriction={Restriction.PATIENT}>
          <ThankYouPage />
        </PrivateRoute>

        <PrivateRoute path="/my-idacio/coolbag" restriction={Restriction.PATIENT}>
          <CoolbagForm />
        </PrivateRoute>

        <PrivateRoute path="/my-idacio" restriction={Restriction.PATIENT}>
          <DynamicPage />
        </PrivateRoute>
        {/*IDACIO END*/}

        {/*HCP ACCESS*/}
        <PrivateRoute exact path="/hcp-access/make-a-request/request-a-rep-call" restriction={Restriction.HCP}>
          <ContactForm />
        </PrivateRoute>
        <PrivateRoute exact path="/hcp-access/request-a-rep-call" restriction={Restriction.HCP}>
          <ContactForm />
        </PrivateRoute>
        <PrivateRoute exact path="/hcp-access/make-a-request/order-a-kit" restriction={Restriction.HCP}>
          <OrderKitForm />
        </PrivateRoute>
        <PrivateRoute path="/hcp-access" restriction={Restriction.HCP}>
          <DynamicPage />
        </PrivateRoute>
        {/*HC ACCESS END*/}
        <Route exact path="/getting-started/your-condition">
          <YourConditionPage />
        </Route>
        <Route path={"/" + homePage}>
          <HomePage />
        </Route>
        <Route path="/login/:userType">
          <Login />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/register">
          <RegisterFormMain />
        </Route>
        <Route path="/forgot-password">
          <ForgotPasswordPage />
        </Route>
        <Route path="/change-password">
          <ChangePassword />
        </Route>
        <Route path="/hcp/register">
          <RegisterFormHCP />
        </Route>
        <Route path="/hcp/thank-you">
          <ThankYouForRegisteringPage breadcrumb="For HCPs" />
        </Route>
        <Route path="/patient/register">
          <RegisterFormPatient />
        </Route>
        <Route path="/patient/thank-you">
          <ThankYouForRegisteringPage breadcrumb="For patients" />
        </Route>
        <PrivateRoute path="/profile">
          <AccountPage />
        </PrivateRoute>
        <Route path="/not-found">
          <NotFoundPage />
        </Route>
        <Route exact path="/">
          <Redirect to={homePage} />
        </Route>
        <Route path="">
          <DynamicPage />
        </Route>
      </Switch>
    </ErrorBoundaryPage>
  );
};
